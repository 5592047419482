import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import AppLoader from './AppLoader';

const App = lazy(() => import('./App'));

/* Sentry.init({
  dsn: 'https://675bc1366eaf4aec943f1f800f5c755d@o510288.ingest.sentry.io/5605713',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
}); */

ReactDOM.render(
  <Suspense fallback={<AppLoader />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);
