import React from 'react';
import { ReactComponent as Svg } from './components/loading/spinner.svg';

const AppLoader: React.FC = () => (
  <div className="opacity-20 w-full min-h-full flex items-center justify-center">
    <div className="text-center">
      <div style={{ width: 40, height: 40, margin: '1px auto 0' }}>
        <Svg />
      </div>
    </div>
  </div>
);

export default AppLoader;
